import React from 'react';


function Footer() {

    return (
        <section className="Footer is-fixed-bottom res-padding-1 has-background-nlf-cream">
            <div className="columns is-marginless">
                <div className="column is-center center">
                    <div className="center">
                        <a href="https://instagram.com/ninelivesfarms" rel="noopener noreferrer" target="_blank">
                            <span className="icon footer-spacing">
                              <a className="fa fa-3x fa-instagram"></a>
                            </span>
                        </a>
                        <a href="https://www.facebook.com/ninelivesfarms" rel="noopener noreferrer" target="_blank">
                            <span className="icon facebook-spacing">
                              <a className="fa fa-3x fa-facebook-square"></a>
                            </span>
                        </a>
                        <p className="tiny-text footer-spacing">©2024</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer;