import NLFLogo from "../images/nlf_logo_bottom.svg";
import NavBar from "./NavBar";
import Footer from "./Footer";

function ErrorPage() {
    return (
        <div className="ErrorPage hero is-fullh has-background-black-ter">
            <NavBar/>
            <div className="container center-column abouttext-container">
                <div className="container center-column padding-2">
                    <p className="welcome-text"> Oops, Looks like you got lost.</p>
                    <p className="welcome-text"> This path goes nowhere.</p>
                    <img className="logo-image" src={NLFLogo} alt="NLFLogo"/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default ErrorPage;