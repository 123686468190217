import NavBar from "./NavBar";
import Footer from "./Footer";
import NLFLogo from "../images/logo_no_text.svg";

function Home() {
    return (
        <div className="Home hero is-fullh has-background-nlf-cream">
            <NavBar/>
            <div className="container center-column abouttext-container">
                <div className="container center-column padding-2">
                    <img className="logo-image" src={NLFLogo} alt="NLFLogo"/>
                    <p className="welcome-text  res-padding-1">NINE LIVES FARMS</p>
                    <p className="para-text has-text-centered"> Committed to sustainability, quality, and equity.</p>
                    <p className="para-text has-text-centered"> We produce a wide variety of food crops, agricultural items, and value added goods. </p>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Home;