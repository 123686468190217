import React, {useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import NLFLogo from "../images/logo_no_text.svg";
function NavBar() {

    let locs = useLocation().pathname.split('/');
    let suffix = locs[1].toLowerCase();
    const home = suffix === "";
    const products = suffix === "products";
    const about = suffix === "about";
    const contact = suffix === "contact";

    //make current page button none
    useEffect(() => {
        try{
            if(home) {
                document.getElementById("home_button").style.opacity = "0.5";
            }else if(products){
                document.getElementById("products_button").style.opacity = "0.5";
            }else if(about){
                document.getElementById("about_button").style.opacity = "0.5";
            }else if(contact){
                document.getElementById("contact_button").style.opacity = "0.5";
            }
        }catch(err){
            console.error(err.message);
        }
    },[about, contact, home, products]);

    return (
        <section className="NavBar has-background-header">
            <div className="columns navbar-columns has-text-weight-bold">
                <div className="column">
                    <div className="level-left center-row">
                        <div className="level-item">
                            <img className="NLFlogo-image" src={NLFLogo} alt="NLFLogo"/>
                        </div>
                        <div className="level-item">
                            <Link to="/" id="home_button">
                                <a className="nav-text">Home</a>
                            </Link>
                            <Link to="/About" id="about_button">
                                <a className="nav-text">About</a>
                            </Link>
                            <Link to="/Products" id="products_button">
                                <a className="nav-text">Products</a>
                            </Link>
                            <Link to="/Contact" id="contact_button">
                                <a className="nav-text">Contact</a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NavBar;