import React from 'react';
import NavBar from "./NavBar";
import Footer from "./Footer";


function Contact() {

    return (
    <div className="Contact hero is-fullh has-background-nlf-cream">
        <NavBar/>
        <div className="columns">
            <div className="column">
                <div className="container has-text-centered">
                    <div className="contact-touch-hint res-padding-1">
                        <a href="mailto:mail@j0sh.codes" rel="noopener noreferrer" target="_blank" className="contact-text">Call us at: 908-416-3864</a>
                    </div>
                    <div className="contact-touch-hint res-padding-1">
                        <a href="mailto:mail@j0sh.codes" rel="noopener noreferrer" target="_blank" className="contact-text">Email us at: NineLivesFarms@gmail.com</a>
                    </div>
                    <div className="contact-touch-hint res-padding-1">
                        <a href="mailto:mail@j0sh.codes" rel="noopener noreferrer" target="_blank" className="contact-text">Visit us at: 2216 NJ-57 Washington, NJ 07882</a>
                    </div>
                    <br/>
                    <p className="contact-text2 res-padding-2"> Farm Business Hours: By Appointment </p>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
    );
}

export default Contact;