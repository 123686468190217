import React from 'react';
import NavBar from "./NavBar";
import Footer from "./Footer";


function Products() {
    return (
        <div className="Products hero is-fullh has-background-nlf-cream">
            <NavBar/>

            <div className="container has-text-left padding-1">
                <br/>
                <p className="para-text has-text-centered"> 2024 Farm Products</p>
                <br/><br/>
                { /* Vegetables */ }
                <div className="card">
                    <header className="card-header has-background-header">
                        <p className="card-header-title product-title-text">Fruits and Vegetables</p>
                    </header>
                    <div className="card-content">
                        <div className="content about-text has-text-centered">
                            Tomatoes, Peppers, Eggplants, Greens, Cucurbits, Garlic, Onions, Sweet Corn<br/>
                        </div>
                    </div>
                </div>
                <br/>
                { /* Herbs */ }
                <div className="card">
                    <header className="card-header has-background-header">
                        <p className="card-header-title product-title-text">Herbs</p>
                    </header>
                    <div className="card-content">
                        <div className="content about-text has-text-centered">
                            Cilantro, Parsely, Rosemary, Sage, Lavander, Basil, Tulsi, Thyme, Chives, Oregano<br/>
                        </div>
                    </div>
                </div>
                <br/>
                { /* Hemp */ }
                <div className="card">
                    <header className="card-header has-background-header">
                        <p className="card-header-title product-title-text">Hemp</p>
                    </header>
                    <div className="card-content">
                        <div className="content about-text has-text-centered">
                            Farmers CBD Flower (Untrimmed), Trimmed CBD Flower, CBD Pre Rolls 5 Pack, CBD Tincture, CBD Salve<br/>
                        </div>
                    </div>
                </div>
                <br/>
                { /* Hot Sauce and Salsa */ }
                <div className="card">
                    <header className="card-header has-background-header">
                        <p className="card-header-title product-title-text">Hot Sauce and Salsa</p>
                    </header>
                    <div className="card-content">
                        <div className="content about-text has-text-centered">
                            Handmade Hot Sauces and Fresh Salsa<br/>
                        </div>
                    </div>
                </div>
                <br/>
                { /* Honey */ }
                <div className="card">
                    <header className="card-header has-background-header">
                        <p className="card-header-title product-title-text">Honey</p>
                    </header>
                    <div className="card-content">
                        <div className="content about-text has-text-centered">
                            Local NJ Honey, Green Honey - CBD infused NJ Honey, Extra Life - Herb infused NJ Honey (Thyme, Sage, Rosemary), Sleepy Time - Herb infused NJ Honey (Lavander, Chamomile)<br/>
                        </div>
                    </div>
                </div>
                <br/>
                { /* Seasonings */ }
                <div className="card">
                    <header className="card-header has-background-header">
                        <p className="card-header-title product-title-text">Seasonings</p>
                    </header>
                    <div className="card-content">
                        <div className="content about-text has-text-centered">
                            Maine Sea Salt Mixes, Pepper Powder Mixes (Medium, Hot, Super Hot), Dried Herb Mixes<br/>
                        </div>
                    </div>
                </div>
                <br/>
                { /* Vinegar */ }
                <div className="card">
                    <header className="card-header has-background-header">
                        <p className="card-header-title product-title-text">Vinegar</p>
                    </header>
                    <div className="card-content">
                        <div className="content about-text has-text-centered">
                            Herbal Vinegar (Chive Blossom, Thyme, Rosemary)
                            <br/>
                        </div>
                    </div>
                </div>
                <br/>
                { /* Flowers */ }
                <div className="card">
                    <header className="card-header has-background-header">
                        <p className="card-header-title product-title-text">Flowers</p>
                    </header>
                    <div className="card-content">
                        <div className="content about-text has-text-centered">
                            Sunflowers, Cut Flowers<br/>
                        </div>
                    </div>
                </div>
                <br/>
                { /* Forest Products */ }
                <div className="card">
                    <header className="card-header has-background-header">
                        <p className="card-header-title product-title-text">Forest Products</p>
                    </header>
                    <div className="card-content">
                        <div className="content about-text has-text-centered">
                            Firewood, Cutting Boards<br/>
                        </div>
                    </div>
                </div>
                <br/><br/>
            <Footer/>
            </div>
        </div>
    );
}

export default Products;