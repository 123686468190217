import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import './styles/app.css';
import './styles/text.css';
import 'bulma/css/bulma.min.css';
import 'font-awesome/css/font-awesome.min.css';
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Products from "./components/Products";
import ErrorPage from "./components/ErrorPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Home/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: '/about',
        element: <About/>,
    },
    {
        path: '/contact',
        element: <Contact/>,
    },
    {
        path: '/products',
        element: <Products/>,
    }])

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <RouterProvider router={router}/>
  </React.StrictMode>
);
